.dmn-drd-container {
  width: 100%;
  height: 100%;
  position: relative;
}

.dmn-drd-container * {
  box-sizing: border-box;
}

.djs-overlay .drill-down-overlay {
  font-size: 16px;
  background: #52b415;
  color: white;
  text-align: left;
  border-radius: 1px;
  padding: 0 2px;
}

.dmn-definitions {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: #FAFAFA;
  border: solid 1px #CCC;
  border-radius: 2px;
  padding: 4px;
}

.dmn-definitions .dmn-definitions-name {
  font-size: 1.5em;
  padding: 3px;
}

.dmn-definitions .dmn-definitions-name:focus {
  outline: none;
}

.dmn-definitions .dmn-definitions-id {
  font-family: monospace;
  margin-top: 2px;
  padding: 3px;
}

.dmn-definitions .dmn-definitions-id:focus {
  outline: none;
}

.dmn-definitions > [contenteditable]:hover,
.dmn-definitions > [contenteditable]:focus {
  padding: 2px;
  background-color: white;
  border-radius: 2px;
  border: 1px solid #aaaaaa;
}

.djs-container.with-palette .dmn-definitions {
  left: 80px;
}

.djs-container.with-palette-two-column .dmn-definitions {
  left: 130px;
}